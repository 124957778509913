import {
  Market,
  NavTabs,
  setTickersSorting,
  TickersOrderBy,
  TickersSortingOrder,
  useAppDispatch,
  useAppSelector,
} from '@openware/opendax-web-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { LandingBlockHeader } from '../BlockHeader'
import { MarketsTable } from '../MarketsTable'

enum MarketTabs {
  MostActive = 'most active',
  Gainers = 'gainers',
  Losers = 'losers',
}

export const LandingMarketsWidget: React.FC = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState<MarketTabs>(MarketTabs.MostActive)

  const markets = useAppSelector(state => state.markets.markets)
  const tickers = useAppSelector(state => state.tickers.tickers)

  const dispatch = useAppDispatch()

  const intl = useIntl()

  const translate = React.useCallback((id: string) => intl.formatMessage({ id }), [])

  const marketTabs = [
    {
      name: translate('page.landing.markets.tabs.mostActive'),
      isCurrentTab: selectedTab === MarketTabs.MostActive,
    },
    {
      name: translate('page.landing.markets.tabs.gainers'),
      isCurrentTab: selectedTab === MarketTabs.Gainers,
    },
    {
      name: translate('page.landing.markets.tabs.losers'),
      isCurrentTab: selectedTab === MarketTabs.Losers,
    },
  ]

  const displayedMarkets = React.useMemo(() => {
    if (!markets.length || !tickers) {
      return []
    }

    const filteredMarkets = (Object.keys(tickers) || [])
      .map(tickerName => markets.find((market: Market) => market.id === tickerName)!)
      .filter(Boolean)

    return filteredMarkets.filter(c => c !== undefined)
  }, [markets, tickers])

  React.useEffect(() => {
    switch (selectedTab) {
      case MarketTabs.MostActive:
        dispatch(
          setTickersSorting({
            orderBy: TickersOrderBy.VOLUME,
            sortingOrder: TickersSortingOrder.DESCENDING,
          }),
        )
        break
      case MarketTabs.Gainers:
        dispatch(
          setTickersSorting({
            orderBy: TickersOrderBy.PRICE_CHANGE,
            sortingOrder: TickersSortingOrder.DESCENDING,
          }),
        )
        break
      case MarketTabs.Losers:
        dispatch(
          setTickersSorting({
            orderBy: TickersOrderBy.PRICE_CHANGE,
            sortingOrder: TickersSortingOrder.ASCENDING,
          }),
        )
        break
    }
  }, [selectedTab])

  const handleSetTab = React.useCallback((route: string) => {
    setSelectedTab(route as MarketTabs)
  }, [])

  return (
    <div className="flex flex-col w-full lg:mt-9">
      <div className="px-[18px] sm:px-6 lg:px-0">
        <LandingBlockHeader title={translate('page.landing.markets.title')} />
      </div>
      <div className="mx-[18px] sm:mx-6 lg:mx-0 my-4 md:mt-3 md:mb-5">
        <NavTabs
          tabs={marketTabs}
          onClick={handleSetTab}
          activeTabClassName="bg-primary-cta-color-60 text-primary-cta-layer-color-60"
          defaultTabClassName="text-neutral-control-layer-color-60 hover:bg-neutral-control-color-30"
          desktopTabsClassName="px-2 py-2 sm:py-0.5 font-metro-bold text-md rounded"
          shouldRenderDropdownMobile={false}
        />
      </div>
      <div className="mx-[18px] sm:mx-6 lg:mx-0 text-sm font-metro-semibold">
        <MarketsTable markets={displayedMarkets?.length ? displayedMarkets.slice(0, 10) : []} tickers={tickers} />
      </div>
    </div>
  )
}
