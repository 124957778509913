import { useSetMobileDevice } from '@openware/opendax-web-sdk'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const Banner: React.FC = () => {
  const isMobile = useSetMobileDevice()

  return (
    <div className="w-auto md:w-[452px] lg:w-auto border border-divider-color-20 rounded shadow pl-5 py-5 mx-[18px] sm:mx-6 lg:mx-0 my-12 sm:mb-12 lg:mt-14 flex justify-between">
      <div className="w-full md:w-1/2">
        <div className="flex justify-between items-center text-text-color-100 w-[190px]">
          <span className="font-bold text-lg">
            <FormattedMessage id="page.landing.banner.title" />
          </span>
        </div>
        <div className="pt-5 pb-3">
          <span className="text-sm font-normal text-text-color-90">
            <FormattedMessage id="page.landing.banner.description" />
          </span>
        </div>
        <div className="flex md:order-2 mt-2 cursor-pointer">
          <a className="button button--main h-9 !w-28 rounded-sm" href="https://terminal.yellow.com" target="_blank">
            <span className="button__inner font-metro-bold h-9">
              <FormattedMessage id="page.landing.banner.button" />
            </span>
          </a>
        </div>
      </div>
      <div className="relative flex items-center justify-end w-full md:w-1/2">
        {isMobile ? (
          <Image
            src="/images/banner-mobile.png"
            alt="Banner"
            layout="fill"
            objectFit="contain"
            objectPosition="right"
          />
        ) : (
          <Image src="/images/banner.png" alt="Banner" layout="fill" objectFit="contain" />
        )}
      </div>
    </div>
  )
}
