import { useSetMobileDevice } from '@openware/opendax-web-sdk'
import { NextIcon } from 'assets/images/Arrow'
import { LandingBlockHeader } from 'components/Landing'
import { convertPlainText } from 'helpers/convertPlainText'
import Image from 'next/image'
import Link from 'next/link'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Article } from 'types'

interface LatestArticlesProps {
  articles?: Article[]
  /**
   * Response from server for detecting mobile device
   */
  responseIsMobile?: boolean
}

export const LatestArticles: React.FC<LatestArticlesProps> = ({
  articles = [],
  responseIsMobile,
}: LatestArticlesProps) => {
  const isMobile = useSetMobileDevice(false, 1025)

  const renderTitle = useMemo(() => {
    return <LandingBlockHeader title={<FormattedMessage id="page.landing.articles.title" />} />
  }, [])

  const renderArticleTitle = (title: string) => {
    if (!title) return <></>

    return <div className="text-lg text-text-color-100 font-metro-bold break-words line-clamp-2">{title}</div>
  }

  const renderDesktopArticles = useCallback(() => {
    const articleItems = articles.map((item, i) => {
      return (
        <div key={i}>
          <div className="border-t border-divider-color-20" />
          <div className="my-6 flex items-start justify-between basis">
            <div className={`line-clamp-2  pr-3 ${item.image_url && 'basis-1/2'}`}>
              {renderArticleTitle(item.title)}
              <div className="text-base text-text-color-80 mt-1 mb-2 break-words line-clamp-3">
                {convertPlainText(item.content)}
              </div>
              <Link href={`/articles/${item.asset_code}/${item.slug}`}>
                <a
                  aria-label={item.title}
                  className="flex group items-center w-fit font-metro-medium text-sm text-secondary-cta-color-60 hover:text-secondary-cta-color-80"
                >
                  <span>
                    <FormattedMessage id="page.landing.articles.more" />
                  </span>
                  <NextIcon
                    classNames="transition hover:duration-200 group-hover:translate-x-1 ml-2"
                    fill="var(--app-secondary-cta-color-60)"
                    disabled={false}
                  />
                </a>
              </Link>
            </div>
            {item.image_url && (
              <div className="ml-[18px] w-[172px] h-[115px] rounded relative basis-1/2">
                {/* eslint-disable-next-line */}
                                <Image
                                    src={item.image_url}
                                    layout="fill"
                                    objectFit="contain"
                                />
              </div>
            )}
          </div>
        </div>
      )
    })

    return articleItems
  }, [articles])

  const renderMobileReivews = useCallback(() => {
    const articleItems = (
      <div className="w-full overflow-scroll no-scrollbar pl-[18px] pr-[6px] sm:pl-6 md:pr-3 lg:px-0">
        <div className="flex w-fit">
          {articles?.length &&
            articles.map((item, i) => {
              return (
                <div
                  key={i}
                  className="flex w-[318px] border border-divider-color-20 p-6 rounded mr-3 sm:last:mr-0 md:last:mr-3"
                >
                  <div className="mr-12 w-full">
                    {renderArticleTitle(item.title)}
                    <div className="text-base text-text-color-80 mt-1 mb-2 break-all line-clamp-3">
                      {convertPlainText(item.description)}
                    </div>
                    <Link href={`/articles/${item.asset_code}/${item.slug}`}>
                      <a className="flex items-center w-fit text-sm text-secondary-cta-color-60">
                        <span>
                          <FormattedMessage id="page.landing.articles.more" />
                        </span>
                        <NextIcon classNames="ml-2" fill="var(--app-secondary-cta-color-60)" disabled={false} />
                      </a>
                    </Link>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )

    return articleItems
  }, [articles])

  return articles?.length ? (
    <div className="w-full mt-12 mb-12 md:mt-9">
      <div className="px-[18px] sm:px-6 lg:px-0">{renderTitle}</div>
      {(isMobile === undefined ? responseIsMobile : isMobile) ? renderMobileReivews() : renderDesktopArticles()}
    </div>
  ) : null
}
