import classnames from 'classnames'
import { FeaturedBadge } from 'components/FeaturedBadge'
import React from 'react'

interface LandingBlockHeaderProps {
  title: string | React.ReactNode
  titleClassName?: string
  isPinned?: boolean
  ContainerTag?: any
}

export const LandingBlockHeader: React.FC<LandingBlockHeaderProps> = ({
  title,
  titleClassName,
  isPinned,
  ContainerTag = 'div',
}: LandingBlockHeaderProps): JSX.Element => {
  const titleCx = classnames('my-2', titleClassName)

  return (
    <div className="flex flex-col">
      {!isPinned && <div className="bg-primary-cta-color-40 w-12 h-1" />}
      <div className="flex flex-col-reverse lg:flex-row lg:items-center lg:justify-start">
        <ContainerTag className={titleCx}>{title}</ContainerTag>
        {isPinned && <FeaturedBadge />}
      </div>
    </div>
  )
}

LandingBlockHeader.defaultProps = {
  titleClassName: 'text-xl font-metro-semibold text-text-color-100',
}
