import { useAppSelector } from '@openware/opendax-web-sdk'
import { PoweredByIcons } from 'assets/images/PoweredByIcons'
import Image from 'next/image'
import React, { ReactNode } from 'react'
import * as tw from 'tailwind.config'
import { LandingBlockHeader } from '../BlockHeader'
const textColors = tw.theme.extend.textColor

interface ListItem {
  name: string | ReactNode
  href: string
  icon: string
}

export interface ListOptions {
  item: ListItem[]
}

export interface PoweredProps {
  items?: ListOptions
}

export const PoweredByBlock: React.FC<PoweredProps> = ({ items }: PoweredProps) => {
  const theme: string = useAppSelector(state => state.globalSettings.color)
  const srcLogo = theme === 'dark' ? '/images/YellowLogoDark.png' : '/images/YellowLogo.png'

  return (
    <div className="mt-9 md:mt-0 my-6 px-[18px] sm:px-6 lg:px-0">
      <LandingBlockHeader
        title={
          <div className="justyfy-between flex items-center">
            <span>Powered by</span>
            <div className="relative ml-1 mt-1.5 flex w-[60px] h-[20px] aspect-[3/1]">
              <Image src={srcLogo} alt="Logo" layout="fill" objectFit="contain" />
            </div>
          </div>
        }
      />
      <div className="border border-divider-color-20 rounded mx-0 px-3 p-5 mt-3 lg:mt-0 flex justify-between shadow">
        {items ? (
          <div>
            {items.item?.map((item: ListItem, index: number) => (
              <span
                key={index}
                className="border border-divider-color-20 mr-2 mb-2 px-2 py-1 w-auto font-metro-semibold text-base inline-block text-text-color-70 hover:underline rounded-full"
              >
                <a className="flex items-center" href={item.href}>
                  <PoweredByIcons name={item.icon} primaryColor={textColors['text-color-100']} />
                  <span className="pl-1">{item.name}</span>
                </a>
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}
