import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import { CryptoIcon, Decimal, Market, Table, Ticker, useSetMobileDevice } from '@openware/opendax-web-sdk'
import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'
import { useIntl } from 'react-intl'

interface MarketsTableProps {
  markets: Market[]
  tickers: {
    [pair: string]: Ticker
  }
}

export const MarketsTable: React.FC<MarketsTableProps> = ({ markets, tickers }: MarketsTableProps): JSX.Element => {
  const isMobile = useSetMobileDevice()
  const intl = useIntl()

  const translate = React.useCallback((id: string) => intl.formatMessage({ id }), [])

  const tableHeaders = React.useMemo(() => {
    const headers = [
      translate('page.landing.markets.table.headers.symbol'),
      translate('page.landing.markets.table.headers.volume'),
      translate('page.landing.markets.table.headers.change'),
    ]
    const lastPriceHeader = translate('page.landing.markets.table.headers.lastPrice')

    return isMobile ? headers : [...headers, lastPriceHeader]
  }, [isMobile])

  const renderPriceChangePercentValue = React.useCallback((value: number) => {
    const cxPriceChangePercent = classnames('flex items-center font-metro-medium px-1 py-0.5 rounded-sm', {
      'bg-bid-10 text-bid-60': value >= 0,
      'bg-ask-10 text-ask-60': value < 0,
    })

    return (
      <span className={cxPriceChangePercent}>
        {value >= 0 ? <ArrowUpIcon className="h-3.5 mr-0.5" /> : <ArrowDownIcon className="h-3.5 mr-0.5" />}
        <Decimal fixed={2} thousSep=",">
          {value < 0 ? value * -1 : value}
        </Decimal>
        %
      </span>
    )
  }, [])

  const data = React.useMemo(() => {
    return (markets || []).map((market: Market) => {
      const currentMarketTicker = tickers[market.id]

      const tableCells = [
        <Link href={`/trading/${market.id}`} key="link-1">
          <a className="block py-3 !px-2">
            <div className="flex items-center">
              <CryptoIcon code={market.base_unit} classNameSpan="flex h-6 w-6 ml-1.5 mr-1.5" />
              <span className="text-base text-text-color-90 mr-0.5">{market.base_unit?.toUpperCase()}</span>
              <span className="text-base text-text-color-50 ">{market.quote_unit?.toUpperCase()}</span>
            </div>
          </a>
        </Link>,
        <Link href={`/trading/${market.id}`} key="link-2">
          <a className="block py-3 !px-2 text-text-color-70">
            {currentMarketTicker?.volume ? (
              <Decimal fixed={0} thousSep=",">
                {currentMarketTicker?.volume}
              </Decimal>
            ) : (
              0
            )}
          </a>
        </Link>,
        <Link href={`/trading/${market.id}`} key="link-3">
          <a className="block py-3 !px-2">
            <div className="flex items-center justify-end">
              {renderPriceChangePercentValue(currentMarketTicker?.price_change_percent || 0)}
            </div>
          </a>
        </Link>,
      ]

      const lastPriceCell = (
        <Link href={`/trading/${market.id}`} key="link-4">
          <a className="block py-3 !px-2 text-text-color-90 w-full">
            <div>
              {currentMarketTicker?.last ? (
                <Decimal fixed={market.price_precision} thousSep=",">
                  {currentMarketTicker?.last}
                </Decimal>
              ) : (
                0
              )}
            </div>
          </a>
        </Link>
      )

      return isMobile ? tableCells : [...tableCells, lastPriceCell]
    })
  }, [markets, tickers, isMobile])

  return (
    <Table
      tableHeaderTitles={tableHeaders}
      data={data}
      paddingHeaderClass="py-1.5 !px-2 capitalize text-right"
      paddingTableClass="!px-0 text-right"
      tableHeaderClassNames="text-text-color-50"
      tableDividerColor="border-divider-color-20"
      defaultRowClassName="border-b border-divider-color-20 transition hover:duration-200 hover:bg-neutral-control-color-30 cursor-pointer"
      isStripedTableEnabled={false}
    />
  )
}
