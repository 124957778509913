import { FC } from 'react'

interface PoweredByIconsProps {
  name: string
  primaryColor: string
}

export const PoweredByIcons: FC<PoweredByIconsProps> = ({ name, primaryColor }: PoweredByIconsProps) => {
  switch (name) {
    case 'articles':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.78889 19.2222L4.78889 11.7667L11.7667 4.78889L19.2222 4.78889L19.2222 19.2222L4.77778 19.2222L4.78889 19.2222ZM2 22L22 22L22 2L10.6222 2L2 10.6222L2 22Z"
            fill="#419E6A"
          />
          <path
            d="M10.6999 2.01148L13.4777 2.01148L13.4777 13.4893L2.011 13.4893L2.01099 10.7115L10.6999 10.7115L10.6999 2.01148Z"
            fill="#419E6A"
          />
          <path d="M15.5 15L15.5 17.2778L8.5 17.2778L8.5 15L15.5 15Z" fill="#419E6A" />
          <path d="M16.9667 8.72222L16.9667 11L12.5 11L12.5 8.72222L16.9667 8.72222Z" fill="#419E6A" />
        </svg>
      )
    case 'send':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.2541 15.3518H16.4748V7.52523H8.64823V4.74591H19.2541V15.3518Z" fill="#1D4ED8" />
          <path d="M16.8829 5.1526L7.5439 14.4916L9.50918 16.4569L18.8482 7.11788L16.8829 5.1526Z" fill="#1D4ED8" />
          <path d="M5.04614 22.0002V18.9541H2V22.0002H5.04614Z" fill="#1D4ED8" />
        </svg>
      )
    case 'tokens':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.9324 11.9843L12.0175 8.06948L8.1027 11.9843L12.0175 15.8991L15.9324 11.9843Z" fill="#DA9D00" />
          <path
            d="M12.0052 4.91293C13.4036 4.90854 14.7719 5.31892 15.9371 6.09219C17.1023 6.86546 18.012 7.96688 18.5512 9.25718C19.0904 10.5475 19.2349 11.9687 18.9664 13.3411C18.6979 14.7135 18.0285 15.9755 17.0427 16.9674C16.057 17.9593 14.7993 18.6367 13.4286 18.9138C12.0579 19.191 10.6358 19.0554 9.34214 18.5243C8.04848 17.9933 6.94136 17.0905 6.16078 15.9302C5.3802 14.7699 4.96121 13.4042 4.95681 12.0058C4.95388 11.0772 5.13388 10.1572 5.48651 9.29826C5.83914 8.4393 6.3575 7.6582 7.01201 6.99957C7.66651 6.34094 8.44433 5.81767 9.30106 5.45964C10.1578 5.10162 11.0766 4.91584 12.0052 4.91293ZM12.0052 2.13361C10.058 2.13361 8.15449 2.71074 6.53517 3.79208C4.91584 4.87343 3.65335 6.41046 2.90718 8.209C2.16101 10.0075 1.96466 11.9869 2.34292 13.897C2.72118 15.8071 3.65709 17.5622 5.0324 18.9406C6.40772 20.319 8.16072 21.2589 10.07 21.6415C11.9792 22.024 13.959 21.8321 15.7592 21.09C17.5594 20.3479 19.0993 19.0889 20.1843 17.472C21.2693 15.8551 21.8507 13.9529 21.8551 12.0058C21.858 10.7104 21.6054 9.42714 21.1117 8.22953C20.618 7.03191 19.8929 5.94344 18.978 5.02644C18.063 4.10943 16.9762 3.3819 15.7797 2.88551C14.5832 2.38911 13.3006 2.1336 12.0052 2.13361Z"
            fill="#DA9D00"
          />
        </svg>
      )
    case 'yellow':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_22361_4960)">
            <path
              d="M13.7748 9.91913H10.252C10.1342 9.91977 10.0214 9.96684 9.93815 10.0501C9.85485 10.1334 9.80777 10.2462 9.80713 10.364V13.6048C9.80777 13.7226 9.85485 13.8354 9.93815 13.9187C10.0214 14.002 10.1342 14.049 10.252 14.0497H13.7748C13.8926 14.049 14.0054 14.002 14.0887 13.9187C14.172 13.8354 14.2191 13.7226 14.2197 13.6048V10.364C14.2191 10.2462 14.172 10.1334 14.0887 10.0501C14.0054 9.96684 13.8926 9.91977 13.7748 9.91913Z"
              fill={primaryColor}
            />
            <path
              d="M6.77829 10.0601C6.87615 10.0584 6.97156 10.0292 7.05366 9.97593C7.13575 9.92263 7.20123 9.84736 7.24264 9.75867C7.65973 8.84852 8.32775 8.07619 9.16832 7.53232C10.0089 6.98845 10.9871 6.69559 11.9883 6.68811C12.9917 6.68395 13.9746 6.9718 14.8173 7.51658C15.6599 8.06137 16.3259 8.83954 16.7339 9.75624C16.7738 9.84628 16.8388 9.92287 16.9212 9.97679C17.0036 10.0307 17.0998 10.0597 17.1983 10.0601H21.1173C21.165 10.0632 21.2127 10.0544 21.2561 10.0346C21.2996 10.0147 21.3374 9.98445 21.3663 9.94641C21.3952 9.90837 21.4142 9.86376 21.4217 9.81658C21.4292 9.7694 21.4248 9.72111 21.4091 9.67601C20.8246 7.63104 19.5899 5.83208 17.8919 4.55136C16.1939 3.27065 14.1249 2.57788 11.998 2.57788C9.87115 2.57788 7.80216 3.27065 6.10412 4.55136C4.40609 5.83208 3.17143 7.63104 2.58696 9.67601C2.57236 9.72129 2.56895 9.76942 2.57703 9.8163C2.58512 9.86318 2.60445 9.9074 2.63337 9.94517C2.6623 9.98293 2.69995 10.0131 2.7431 10.0331C2.78625 10.0532 2.83361 10.0624 2.88113 10.0601H6.771H6.77829Z"
              fill={primaryColor}
            />
            <path
              d="M17.2295 13.9573C17.1311 13.9578 17.0348 13.9868 16.9525 14.0407C16.8701 14.0946 16.805 14.1712 16.7652 14.2612C16.3531 15.1749 15.6857 15.9499 14.8432 16.493C14.0008 17.0361 13.0194 17.324 12.0171 17.3221C11.0175 17.3121 10.0413 17.0181 9.20234 16.4744C8.3634 15.9308 7.69628 15.1598 7.27875 14.2515C7.2389 14.1615 7.17386 14.0849 7.09148 14.031C7.00909 13.9771 6.91286 13.9481 6.8144 13.9476H2.90265C2.85498 13.9445 2.80729 13.9533 2.76384 13.9732C2.72039 13.993 2.68254 14.0233 2.65364 14.0614C2.62475 14.0994 2.60573 14.144 2.59827 14.1912C2.59082 14.2384 2.59516 14.2867 2.61091 14.3317C3.19538 16.3767 4.43005 18.1757 6.12808 19.4564C7.82611 20.7371 9.8951 21.4299 12.022 21.4299C14.1488 21.4299 16.2178 20.7371 17.9158 19.4564C19.6139 18.1757 20.8485 16.3767 21.433 14.3317C21.447 14.2865 21.45 14.2385 21.4417 14.1919C21.4333 14.1453 21.414 14.1013 21.3852 14.0637C21.3564 14.0261 21.319 13.9959 21.2761 13.9757C21.2333 13.9555 21.1862 13.9459 21.1388 13.9476H17.2295V13.9573Z"
              fill={primaryColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_22361_4960">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    default:
      return null
  }
}
